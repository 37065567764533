@use '/src/routes/admin/styles/theme';
@use '/src/routes/admin/styles/mixins';
@use '/src/routes/admin/styles/functions';

$input-right-padding: 55px;
$input-top-margin: 6px;

.input-w {
	position: relative;
}

.suffix {
	position: absolute;
	right: 0;
	bottom: 0;
	display: flex;
	width: $input-right-padding;
	height: calc(100% - #{$input-top-margin});
	justify-content: center;
	align-items: center;
}

.field-chars-count {
	position: absolute;
	top: -20px;
	right: 0;
}

.error {
	margin: 4px 0;
	font-size: 14px;
}

.label-text {
	padding: 0 0 2px 0;

	&.upper {
		text-transform: uppercase;
	}

	span {
		padding: 0 0 0 4px;
		font-weight: 400;
	}
}

.description {
	display: block;
	padding: 5px 0 0 0;
	font-size: 14px;
	color: theme.$ra-black-dark;
}

.input-field {
	position: relative;

	&.dark {
		.description {
			color: theme.$ra-white-dark;
		}

		input {
			color: theme.$ra-white;
			box-shadow: inset 0 0 0 1px theme.$ra-gray50;

			&:focus {
				box-shadow: inset 0 0 0 2px theme.$ra-gray50;
			}
		}

		::-webkit-input-placeholder {
			/* Edge */
			color: theme.$ra-gray50;
		}

		:-ms-input-placeholder {
			/* Internet Explorer 10-11 */
			color: theme.$ra-gray50;
		}

		::placeholder {
			color: theme.$ra-gray50;
		}
	}

	&.limit-in {
		input {
			margin: 0;
		}

		.field-chars-count {
			top: 50%;
			right: 16px;
			transform: translateY(-50%);
		}
	}

	input {
		width: 100%;
		padding: 12px $input-right-padding 14px 16px;
		margin: $input-top-margin 0 0 0;
		outline: none;
		background-color: transparent;
		border: none;
		transition: box-shadow 0.25s ease-in-out;
		border-radius: 6px;
		box-shadow: inset 0 0 0 1px #ccc;

		&:focus {
			box-shadow: inset 0 0 0 2px theme.$ra-brand;
		}
	}

	::-webkit-outer-spin-button,
	::-webkit-inner-spin-button {
		margin: 0;
		-webkit-appearance: none;
	}

	/* Firefox */
	input[type='number'] {
		-moz-appearance: textfield;
	}

	::-webkit-input-placeholder {
		/* Edge */
		color: theme.$ra-black-mid;
	}

	:-ms-input-placeholder {
		/* Internet Explorer 10-11 */
		color: theme.$ra-black-mid;
	}

	::placeholder {
		color: theme.$ra-black-mid;
	}

	&.has-error input {
		box-shadow: inset 0 0 0 2px theme.$ra-error-color;
	}

	&.hero {
		text-align: center;

		.label-text {
			color: theme.$ra-black;
		}

		.field-chars-count {
			position: static;
			text-align: inherit;
			transform: none;
		}

		input {
			@include mixins.mapToProps(functions.map-deep-get(theme.$ra-font-size, heading, base));

			padding: 0;
			margin: 0;
			text-align: inherit;
			box-shadow: none;

			&::placeholder {
				text-transform: capitalize;
			}
		}
	}
}

.read-only {
	//pointer-events: none;
}

.read-only:where(.light) input {
	background-color: rgb(242, 242, 242);
}

.read-only:where(.dark) input {
	background-color: theme.$ra-white-light;
}
