@use '/src/routes/client/styles/theme';
@use '/src/routes/client/styles/functions';

.cms-repeater {
	--default-size: #{functions.custom-round(20vw)};

	width: var(--default-size);
	height: var(--default-size);
	cursor: pointer;

	[data-editor='true'] {
		cursor: auto;
	}

	[data-editor='true'] &:empty {
		background: theme.$ra-black-soft;
	}
}

.active {
	filter: invert(90%);
}

.filter-trigger,
.pagination-trigger {
	cursor: pointer;
}

.pagination-trigger[disabled] {
	opacity: 0.45;
	pointer-events: none;
}

.loading,
.no-result {
	color: theme.$ra-black-dark;
}

.loading {
	width: 100%;
	padding: 0.1em 0.5em;
	animation: blink 0.75s ease infinite;
}

.no-result {
	display: flex;
	width: 100%;
	height: 100%;
	justify-content: center;
	align-items: center;
	background-color: #efefef;
}

@keyframes blink {
	50% {
		opacity: 0.5;
		background-color: #f7f7f7;
	}
}
