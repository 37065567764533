@use 'sass:map';
@use '/src/routes/client/styles/theme';

$re-toolbar-radius: 24px;

.toolbar-wrapper {
	position: fixed;
	z-index: map.get(theme.$z-index, editor-ui);
	transition: transform 150ms cubic-bezier(0.3, 1.2, 0.2, 1);
	transform-origin: center;

	html body & {
		direction: ltr;
		text-align: left;
	}
}

.toolbar {
	position: static;
	display: flex;
	transform: none !important; /* important: prevent internal plugin behaviour */
	visibility: visible !important; /* important: prevent internal plugin behaviour */
	transition: none !important; /* important: prevent internal plugin behaviour */
	background: #fff;
	border-radius: $re-toolbar-radius;
	box-shadow:
		0 0 5px theme.$ra-white-light,
		0 2px 4px 0 theme.$ra-black-soft,
		0 0 2px 0 theme.$ra-black-soft;

	&::after,
	&::before {
		content: ' ';
		position: absolute;
		top: 100%;
		left: 50%;
		width: 0;
		height: 0;
		border: solid transparent;
		pointer-events: none;
	}

	&::after {
		margin-left: -4px;
		border-color: rgba(255, 255, 255, 0);
		border-top-color: #fff;
		border-width: 4px;
	}

	&::before {
		margin-left: -6px;
		border-color: rgba(221, 221, 221, 0);
		border-top-color: #ddd;
		border-width: 6px;
	}

	.toolbar-wrapper[data-position='bottom'] & {
		box-shadow: 0 -1px 3px 0 rgba(black, 0.15);

		&::before,
		&::after {
			top: auto;
			bottom: 100%;
			transform: scaleY(-1);
		}
	}
}

.button {
	display: flex;
	width: 36px;
	height: 34px;
	justify-content: center;
	align-items: center;
	background: #fbfbfb;
	color: #888;
	border-radius: 0;

	&:hover,
	&:focus {
		background: #f3f3f3;
		outline: 0;

		svg {
			fill: #888;
		}
	}
}

.button-wrapper {
	display: block;

	&:first-child .button {
		padding-left: 12px;
		border-bottom-left-radius: $re-toolbar-radius;
		border-top-left-radius: $re-toolbar-radius;
	}

	&:last-child .button {
		border-bottom-right-radius: $re-toolbar-radius;
		border-top-right-radius: $re-toolbar-radius;
	}
}

.active {
	background: #e6e6e6;
	color: #444;

	svg {
		fill: #444;
	}
}

.link-content {
	display: flex;

	input {
		height: 34px;
		padding: 0 12px 0 16px;
		line-height: 34px;
		background: theme.$ra-white;
		border: none;
		border-radius: $re-toolbar-radius 0 0 $re-toolbar-radius;

		&:focus {
			outline: none;
			box-shadow: inset 0 0 0 1px theme.$ra-semantic-fill;
		}
	}

	button {
		height: 100%;
		padding: 0 12px;
		margin-left: 0;
		background: white;
		border-radius: 0 $re-toolbar-radius $re-toolbar-radius 0;
		color: theme.$ra-black;
		transition: color 200ms ease;

		&:hover {
			color: theme.$ra-black-dark;
		}
	}
}
