@use 'sass:map';
@use '/src/routes/admin/styles/theme';
@use '/src/routes/admin/styles/mixins';
@use '/src/routes/admin/styles/functions';

.light {
	--menu-bg-color: var(--ra-color-white);
	--menu-item-color: var(--ra-color-black);
	--menu-item-hover-bg-color: var(--ra-color-black-light);
}

.dark {
	--menu-bg-color: #{theme.$ra-gray80};
	--menu-item-color: var(--ra-color-white-dark);
	--menu-item-hover-bg-color: var(--ra-color-white-light);
}

.dropdown-o {
	--menu-max-width: 300px;
	--menu-max-height: 35vh;

	:global(.ant-dropdown-menu) {
		max-height: var(--menu-max-height);
		overflow-y: auto;
		background: var(--menu-bg-color);
	}

	:global(.ant-dropdown-menu .ant-dropdown-menu-item) {
		max-width: var(--menu-max-width);
		padding: 6px 8px;
		align-items: flex-start;
		color: var(--menu-item-color);
		font-size: functions.map-deep-get(theme.$ra-font-size, description, base, font-size);
		transition: none;
		user-select: none;
		gap: 8px;
	}

	:global(.ant-dropdown-menu .ant-dropdown-menu-item:hover) {
		background-color: var(--menu-item-hover-bg-color);
	}
}

.no-animation {
	animation-duration: 0ms;
}

.caret {
	position: relative;

	&::after {
		@include mixins.centerY();

		content: '';
		right: -3px;
		border: 4px solid rgba(0, 0, 0, 0);
		border-bottom-width: 0;
		border-top-color: currentColor;
	}

	&:not(:hover)::after {
		opacity: 0.7;
	}
}

.menu {
	position: relative;
	padding: 12px 8px;
	border-radius: 4px;
	background-color: rgba(#fff, 0.85);
	backdrop-filter: blur(10px);

	:global {
		.ant-dropdown-menu {
			min-width: 280px;
			padding: 0;
			background-color: transparent;
			box-shadow: none;

			.ant-dropdown-menu-item-group-title {
				padding: 6px 16px;
				font-size: 11px;
				font-weight: 600;
				color: rgba(#000, 0.38);
			}

			.ant-dropdown-menu-item-group-list {
				padding: 0;
				margin: 0;
			}

			.ant-dropdown-menu-item {
				padding: 5px 16px 7px;
				font-size: 16px;
				line-height: 24px;
			}
		}
	}
}

.search-form {
	display: flex;
	width: 100%;
	padding: 4px 8px 6px;
	margin: 0;
	justify-content: space-between;
	align-items: center;
}

.search-input {
	border: none;
	background-color: transparent;
	outline: none;

	&::placeholder {
		color: rgba(#000, 0.38);
	}
}

.search-submit {
	display: flex;
	width: 24px;
	height: 24px;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	opacity: 0.4;
	transition: opacity 0.3s;

	i {
		display: block;
		width: 70%;
		height: 70%;
	}

	path {
		fill: theme.$ra-black;
	}

	&:hover {
		opacity: 1;
	}
}

.menu-items {
	&.pending {
		opacity: 0.4;
	}
}

.no-results {
	padding: 8px;
	font-size: 14px;
	text-align: center;
	color: rgba(#000, 0.38);
}

.divider {
	height: 1px;
	margin: 8px -8px;
	background-color: rgba(#000, 0.12);
}
