.field {
	width: 16rem;
}

.table {
	width: 100%;
}

.table thead td {
	color: var(--ra-color-white-dark);
	padding-block-end: 1.2rem;
}

.table tbody td {
	height: 4.8rem;
}

.table td:not(:first-child) {
	padding-right: 1.6rem;
}
