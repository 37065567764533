@use '/src/routes/client/styles/theme';
@use '/src/routes/client/styles/mixins';
@use '/src/routes/client/styles/functions';

.search-field {
	--clear-btn-width: #{functions.custom-round(0.86em)};
	--clear-btn-inset-inline-start: #{functions.custom-round(0.8rem)};

	composes: text-field from '../TextField/TextField.scss';
}

.field {
	composes: field from '../TextField/TextField.scss';
	padding-inline-end: calc(var(--clear-btn-width) + var(--clear-btn-inset-inline-start));

	/* clears the ‘X’ from Internet Explorer */
	&::-ms-clear {
		display: none;
		width: 0;
		height: 0;
	}

	&::-ms-reveal {
		display: none;
		width: 0;
		height: 0;
	}

	/* clears the ‘X’ from Chrome */
	&::-webkit-search-decoration,
	&::-webkit-search-cancel-button,
	&::-webkit-search-results-button,
	&::-webkit-search-results-decoration {
		display: none;
	}
}

.btn-clear {
	position: absolute;
	z-index: 1;
	display: none;
	width: var(--clear-btn-width);
	height: 100%;
	inset-inline: auto var(--clear-btn-inset-inline-start);
	inset-block: 0 0;

	.search-field:is(:focus, :focus-within, :hover) & {
		display: block;
	}
}

.btn-clear-body {
	display: flex;
	padding: functions.custom-round(0.15em);
	justify-content: center;
	align-items: center;
	border-radius: 50%;
	color: #b3b3b3;

	.btn-clear:hover & {
		background-color: #ededed;
	}
}
