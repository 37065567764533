.color {
	display: inline-block;
	width: 24px;
	height: 24px;
	border-radius: 50%;
}

.picker {
	width: 220px;
	overflow: hidden;
	background-color: rgba(38, 38, 38, 0.85);
	backdrop-filter: saturate(200%) blur(6px);
	border-radius: 12px;
	font-family: sans-serif;
}

.picker-area {
	height: 180px;
}

.minimized.picker {
	width: 350px;
	height: 120px;

	.picker-area {
		height: 100%;
	}

	.picker-root {
		display: grid;
		height: 100%;
		grid-template-columns: 150px 200px;
	}

	.picker-inputs {
		padding-top: 16px;
	}
}
