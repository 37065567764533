@use '/src/routes/admin/styles/theme';

.gpt-text-response-action {
	position: relative;
}

.input-wrapper {
	margin-bottom: 15px;
}

.label {
	margin-bottom: 6px;
}

.label-with-toggle {
	margin-bottom: 6px;
}

.editable-text {
	&.error {
		:where(.editor-wrapper) {
			box-shadow: inset 0 0 0 2px theme.$ra-error-color;
		}
	}
}

.editor-wrapper {
	padding: 13px 16px;
	border-radius: 6px;
	box-shadow: inset 0 0 0 1px theme.$ra-gray50;

	&:focus-within {
		box-shadow: inset 0 0 0 2px theme.$ra-gray50;
	}

	*[data-mention] {
		padding: 1px 4px 3px;
		border-radius: 5px;
		background-color: rgba(#000, 0.1);
		box-shadow: inset 0 0 0 1px theme.$ra-gray50;
	}
}

.heading {
	margin: 35px 0 15px;
}

.text {
	margin-bottom: 10px;
	opacity: 0.6;
}

.outputs {
	margin-bottom: 35px;

	&-item {
		position: relative;
		padding: 10px 14px;
		border-radius: 10px;
		transition: background-color 0.2s;

		&:hover {
			background-color: rgba(#000, 0.1);

			.remove-btn {
				opacity: 1;
			}
		}
	}
}

.add-btn {
	position: relative;
	margin: 0 auto;

	&-label {
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 10px;

		&-icon {
			width: 24px;
			height: 24px;

			path {
				fill: theme.$ra-brand;
			}
		}
	}
}

.editor-placeholder {
	opacity: 0.2;

	&.has-content {
		opacity: 1;
	}
}

.remove-btn {
	position: absolute;
	top: 50%;
	right: -28px;
	display: flex;
	width: 24px;
	height: 24px;
	justify-content: center;
	align-items: center;
	transform: translateY(-50%);
	opacity: 0;
	background-color: transparent;
	transition: opacity 200ms ease;
	cursor: pointer;

	path {
		fill: theme.$ra-error-ds;
	}

	&:hover {
		opacity: 0.8;
	}
}
