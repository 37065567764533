@use 'sass:map';
@use '/src/routes/client/styles/mixins';
@use '/src/routes/client/styles/theme';
@use '/src/routes/client/styles/functions';

.mentionSuggestions {
	z-index: map.get(theme.$z-index, editor-ui);
	width: 500px;
	max-width: 90vw;
	max-height: 35vh;
	overflow: auto;
	background-color: theme.$ra-gray;
	backdrop-filter: saturate(200%) blur(6px);
	border-radius: 12px;

	html[dir] & {
		direction: ltr;
	}

	&-Entry {
		&,
		&Focused {
			padding: 4px 16px 4px 0;
			border-bottom: 1px solid theme.$ra-white-light;
			cursor: pointer;
		}

		&:hover {
			background-color: theme.$ra-white-light;
			border-bottom-color: transparent;
		}

		&:active {
			background-color: theme.$ra-white-soft;
			border-bottom-color: transparent;
		}

		&Focused {
			background-color: theme.$ra-white-soft;
			border-bottom-color: transparent;
		}

		&Container {
			display: grid;
			overflow: hidden;
			grid-template: auto / 40px 1fr;
		}

		&ContainerLeft {
			display: flex;
			justify-content: center;
		}

		&ContainerRight {
			// ...
		}

		&Text,
		&Title {
			word-break: break-word;
			font-style: normal;
			font-weight: theme.$ra-base-font-weight;
			font-family: theme.$ra-base-font-family;
			line-height: theme.$ra-base-line-height;
		}

		&Text {
			margin-bottom: 2px;
			font-size: 1.4rem;
			color: theme.$ra-white;
		}

		&Title {
			font-size: 1.3rem;
			color: theme.$ra-white-mid;
		}

		&Avatar {
			display: block;
			width: 13px;
		}
	}
}

.header {
	padding: 15px 50px;
}

.header-title {
	color: theme.$ra-white;
	font-size: functions.map-deep-get(theme.$ra-font-size, subheading, base, font-size);
}

.header-subtitle {
	font-size: functions.map-deep-get(theme.$ra-font-size, label, base, font-size);
	color: theme.$ra-white-mid;
}

.category {
	padding-left: 16px;
	font-size: functions.map-deep-get(theme.$ra-font-size, footnote, base, font-size);
	line-height: 2rem;
	background-color: theme.$ra-white-light;
	color: theme.$ra-white-mid;
	font-weight: 600;
}

.tooltip {
	z-index: map.get(theme.$z-index, editor-ui);

	:global {
		.ant-tooltip-arrow-content {
			display: none;
		}

		.ant-tooltip-inner {
			overflow: hidden;
			background-color: theme.$ra-gray15;
			border-radius: 3px;
		}

		.ant-tooltip-inner-content {
			padding: 8px 12px;
			color: theme.$ra-white;
		}
	}

	// hide immediately
	&:global([class*='-leave']) {
		display: none;
	}
}
