@use '/src/routes/admin/styles/theme';
@use '/src/routes/admin/styles/mixins';

.field-inner {
	margin-bottom: 5px;
}

.field {
	.label {
		padding-bottom: 2px;
	}

	:global {
		.select,
		.creatable {
			width: auto;

			&__control {
				min-height: 46px;
				margin: 6px 0 0 0;
				border: none;
				box-shadow: inset 0 0 0 1px #ccc;
				border-radius: 6px;
				transition: box-shadow 0.25s ease-in-out;

				&:hover {
					border-color: transparent;
					box-shadow: inset 0 0 0 1px #ccc;
				}

				&--is-focused {
					box-shadow: inset 0 0 0 2px theme.$ra-brand;

					&:hover {
						border-color: transparent;
						box-shadow: inset 0 0 0 2px theme.$ra-brand;
					}
				}
			}

			&__placeholder {
				color: theme.$ra-black-mid;
			}

			&__value-container {
				padding-right: 0;
				padding-left: 15px;
			}

			&__value-container--is-multi {
				padding-top: 8px;
				padding-bottom: 8px;
			}

			&__input-container {
				color: inherit;
			}

			&__multi-value {
				border-radius: 4px;
				color: theme.$ra-black;
			}

			&__indicator-separator {
				display: none;
			}

			&__menu {
				border-radius: 6px;
			}

			&__option {
				@include mixins.textEllipsis();

				padding: 8px 18px;

				&--is-focused {
					background-color: rgba(0, 168, 126, 0.4);
				}

				&--is-selected {
					background-color: theme.$ra-brand;
				}

				&:active {
					background-color: rgba(0, 168, 126, 0.4);
				}
			}

			&__dropdown-indicator {
				position: relative;
				padding: 13px;

				&::before {
					content: '';
					position: absolute;
					top: calc(50% + 3px);
					left: 4px;
					display: block;
					width: 1px;
					height: 1px;
					border: 4px solid transparent;
					border-top-color: theme.$ra-black-mid;
					transform: translate(-50%, -50%);
				}

				svg {
					display: none;
				}
			}

			&__single-value {
				max-width: fit-content;
				font-size: inherit;
				font-weight: 400;
				color: theme.$ra-black;
			}

			& &__select__input {
				color: theme.$ra-black;
				font-size: 1.6rem;
			}
		}

		.select {
			&__single-value {
				position: relative;
				max-width: fit-content;
				transform: none;
			}
		}
	}

	&.dark {
		.label,
		.field-inner {
			color: theme.$ra-white;
		}

		:global {
			.select,
			.creatable {
				&__control {
					box-shadow: inset 0 0 0 1px theme.$ra-gray50;
					background-color: transparent;

					&:hover {
						border-color: transparent;
						box-shadow: inset 0 0 0 1px theme.$ra-gray50;
					}

					&--is-focused {
						box-shadow: inset 0 0 0 2px theme.$ra-gray50;

						&:hover {
							border-color: transparent;
							box-shadow: inset 0 0 0 2px theme.$ra-gray50;
						}
					}
				}

				&__menu {
					background-color: theme.$ra-gray70;
				}

				&__placeholder {
					color: theme.$ra-gray50;
				}

				&__select__input {
					color: theme.$ra-gray50;
					font-size: 1.6rem;
				}

				&__option {
					&--is-focused {
						background-color: theme.$ra-gray50;
					}

					&--is-selected {
						background-color: theme.$ra-brand;
					}

					&:active {
						background-color: theme.$ra-gray50;
					}

					&--is-disabled,
					&--is-disabled:active {
						color: theme.$ra-white-soft;
						background: none;
					}
				}

				&__dropdown-indicator {
					&::before {
						border-top-color: theme.$ra-gray50;
					}
				}

				&__single-value {
					color: theme.$ra-white;
				}
			}
		}
	}
}

.inline {
	display: flex;
	justify-content: center;
	align-items: center;

	.field-inner {
		margin-bottom: 0;
	}

	.label {
		margin-right: 0;
		transform: translateY(4px);
	}

	:global {
		.select {
			width: auto;

			&__control {
				width: fit-content;
				border-color: transparent;
				box-shadow: none;

				&:hover {
					border-color: transparent;
					box-shadow: none;
				}
			}

			&__single-value {
				position: relative;
				transform: none;
				font-size: 13px;
				font-weight: 700;
				color: theme.$ra-black-dark;
			}

			&__value-container {
				padding-right: 0;
			}

			&__indicator-separator {
				display: none;
			}

			&__dropdown-indicator {
				position: relative;

				&::before {
					content: '';
					position: absolute;
					top: calc(50% + 3px);
					left: 4px;
					display: block;
					width: 1px;
					height: 1px;
					border: 4px solid transparent;
					border-top-color: theme.$ra-black-dark;
					transform: translate(-50%, -50%);
				}

				svg {
					display: none;
				}
			}

			&__menu {
				width: fit-content;
				transform: translateY(-10px);
			}
		}
	}
}

.errors {
	margin: 4px 0;
}

.field.error {
	:global(.select__control) {
		box-shadow: inset 0 0 0 2px theme.$ra-error-color;
	}
}

.field:not(:has(.label)) :global(.select__control) {
	margin-top: 0;
}
