@use '/src/routes/admin/styles/theme';
@use '/src/routes/admin/styles/mixins';

$layout-extra-padding-top: 20px;
$layout-extra-padding-bottom: 50px;
$header-height: 60px;
$footer-height: 220px;
$padding-top: $header-height + $layout-extra-padding-top;

:where(.layout) {
	min-height: calc(100vh - #{$footer-height});
}

:where(.layout-main) {
	padding-top: $header-height + $layout-extra-padding-top;
	padding-bottom: $layout-extra-padding-bottom;
}

:where(.layout-story) {
	min-height: 100vh;
	padding-top: $padding-top;
	padding-bottom: $layout-extra-padding-bottom;
	overflow: hidden;
}
