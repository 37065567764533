@use 'sass:map';
@use '/src/routes/admin/styles/mixins';
@use '/src/routes/admin/styles/theme';

.modal {
	z-index: map.get(theme.$z-index, antd-modal);

	:global {
		.ant-modal-content {
			padding: 4rem;
			border-radius: 2.4rem;
			box-shadow:
				0 1rem 4rem 0 theme.$ra-black-light,
				0 1rem 2rem -1rem theme.$ra-black-soft,
				0 0.2rem 0.4rem -0.2rem theme.$ra-black-soft;
		}

		.ant-modal-header {
			padding: 0;
			margin-bottom: 2.4rem;
			border: none;
		}

		.ant-modal-title {
			font-size: 1.6rem;
			font-weight: 700;
			color: initial;
		}

		.ant-modal-body {
			padding: 0;
		}
	}

	&-body {
		padding: 0;
		white-space: pre-line;
	}

	&-footer {
		display: flex;
		padding: 0;
		margin-top: 4rem;
		flex-flow: row wrap;
		gap: 1.6rem;
	}
}

.dark {
	:global {
		.ant-modal-header {
			background-color: transparent;
		}

		.ant-modal-title {
			color: white;
		}

		.ant-modal-content {
			padding: 2.7rem 3.7rem;
			background-color: #4d4d4d;
			color: white;
		}
	}
}

.full-screen {
	:global {
		.ant-modal {
			top: 0;
			height: 100%;
			max-width: none;
			padding: 0;
			margin: 0;
		}

		.ant-modal-content {
			display: flex;
			height: 100%;
			padding: 0;
			flex-direction: column;
			overflow: hidden;
			border-radius: 0;
			box-shadow: none;
		}

		.ant-modal-body {
			max-height: 100%;
			flex: 1;
		}
	}
}
