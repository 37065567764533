//stylelint-disable
@use 'sass:map';
@use '/src/routes/admin/styles/theme';
@use '/src/routes/admin/styles/mixins';
@use '/src/routes/admin/styles/functions';
@use '/src/routes/admin/components/pages/Story/Flow/vars' as Flow-vars;
@use 'vars';

.list {
	display: inline-flex;
	min-width: calc(100vw - #{Flow-vars.$story-flow-offset-right});
	margin: 0 auto 0;
	height: 100%;
	padding: functions.with-unit(vars.$list-header-height) 0 0 functions.with-unit(vars.$list-padding-x);
	flex-flow: row nowrap;
	justify-content: flex-start;
	align-items: flex-start;
	overflow: visible;
	list-style-type: none;
	color: hsl(0, 0%, 10%);

	&-spacer-r {
		min-width: functions.with-unit(vars.$list-padding-x);
		min-height: 1px;
	}
}

.card-menu-portal {
	position: fixed;
	z-index: 1000;
}

.card {
	position: relative;
	width: 100%;
	padding: 0 0 4px;
	margin: 0;
	overflow: hidden;
	list-style-type: none;
	background: hsla(0, 0%, 43%, 0.9);
	border-radius: 12px;
	transition: box-shadow 0.3s ease;

	&:hover,
	.card-menu-open & {
		.card-menu {
			opacity: 1;
			visibility: visible;
		}
	}

	&:not(:last-child) {
		margin-bottom: 40px;
	}

	&-thumbnail {
		position: relative;
		display: block;
		height: functions.with-unit(vars.$card-thumbnail-height);
		margin: functions.with-unit(-(vars.$card-li-padding) * 0.5) functions.with-unit(-(vars.$card-li-padding))
			functions.with-unit(vars.$card-li-padding) functions.with-unit(-(vars.$card-li-padding) * 2.5);
		background-position: 50% 50%;
		background-size: cover;
		background-color: white;

		&.nav {
			background-color: theme.$ra-black-soft;

			.nav-icon {
				position: absolute;
				top: 55%;
				left: 50%;
				transform: translate(-50%, -50%);
			}
		}
	}

	&-item {
		position: relative;
		display: block;
		width: 100%;
		padding: functions.with-unit(vars.$card-li-padding * 0.5) functions.with-unit(vars.$card-li-padding)
			functions.with-unit(vars.$card-li-padding * 0.5) functions.with-unit(vars.$card-li-padding * 2.5);
		overflow: hidden;
		border: none;
		border-radius: 0;
		font-size: 12px;

		&:last-child {
			border: none;
		}
	}

	&-menu {
		position: absolute;
		top: functions.with-unit(vars.$card-li-padding * 1.5);
		right: functions.with-unit(vars.$card-li-padding * 2);
		z-index: 3;
		opacity: 0;
		visibility: hidden;
		transition: 0.1s ease;

		&-label {
			display: flex;
			width: 24px;
			height: 24px;
			padding: 0 2px;
			align-items: center;
			border-radius: 24px;
			color: theme.$ra-black-dark;
			background-color: theme.$ra-white;
			box-shadow: 0 0 0 1px theme.$ra-black-soft;
		}
	}

	&-body {
		position: relative;
		color: theme.$ra-black-mid;
		user-select: none;
	}

	&-title {
		@include mixins.mapToProps(functions.map-deep-get(theme.$ra-font-size, paragraph, base));

		overflow: hidden;
		margin: 0 36px 0 0;
		padding: 0;
		color: theme.$ra-white;
		background: none;
		outline: none;
		border: none;
		font-family: inherit;
		resize: none;
	}

	&-type {
		@include mixins.absolute(top 12px left 4px);

		display: inline-block;
		padding: 6px 16px 6px;
		border-radius: 50px;
		color: theme.$ra-black-dark;
		background-color: theme.$ra-white;
		box-shadow: 0 0 0 1px theme.$ra-black-soft;
		pointer-events: none;

		&.grey {
			color: theme.$ra-white;
			background-color: theme.$ra-white-soft;
		}
	}

	&.nav {
		background: theme.$ra-white-soft;
	}
}

.point {
	$size: 50;

	// connected indication
	$indication-point-size: 10;
	$indication-point-position: $size * 0.5 - $indication-point-size * 0.5;

	@include mixins.centerY();

	right: -3px;
	width: functions.with-unit($size);
	height: 100%;
	background-color: hsl(0, 0%, 80%);
	cursor: pointer;
	transition: 0.3s ease;

	// HIDE POINTS AREA
	background-color: hsla(0, 0%, 80%, 0) !important;

	&.point-card,
	&.point-step {
		right: auto;
		height: 40px;
		transform: none;
	}

	&.point-card {
		left: #{-$size * 0.5 - 6}px;
		top: 4px;
	}

	&.point-step {
		left: 50%;
		top: 1px;
		transform: translate(-50%, -50%);
	}

	&.point-start,
	&.point-end {
		&::before {
			@include mixins.centerY();

			content: '';
			color: theme.$ra-white-soft;
			width: functions.with-unit($indication-point-size);
			height: functions.with-unit($indication-point-size);
			border: 1px solid currentColor;
			border-radius: 50%;
			left: functions.with-unit($indication-point-position);
			display: none;
		}
	}

	&.point-start {
		.card-active &::before {
			display: block;
		}

		&:hover:before {
			background-color: currentColor;
			border-color: transparent;
		}
	}

	&.point-end {
		pointer-events: none;

		&::before {
			background-color: #fafafa;
			border-color: theme.$ra-black-soft;
		}
	}
}

.step-active ~ .step .point-end::before {
	display: block;
}

.step {
	position: relative;
	display: flex;
	width: functions.with-unit(vars.$step-width);
	height: 100%;
	max-width: functions.with-unit(vars.$step-width);
	min-width: functions.with-unit(vars.$step-width);
	margin: 0 functions.with-unit(vars.$step-gutter) 0 0;
	flex-direction: column;
	flex: 1 1 auto;
	border-radius: 6px;

	&:last-of-type {
		margin-right: 0;
	}
}

.list-header {
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	z-index: map.get(theme.$z-index, list-header);
	display: flex;
	height: functions.with-unit(vars.$list-header-height);
	padding: 0 functions.with-unit(vars.$list-padding-x);
	box-shadow: 0 1px 0 0 theme.$ra-white-soft;
}

.step-header {
	position: absolute;
	top: functions.with-unit(-(vars.$step-header-height));
	right: 0;
	left: 0;
	z-index: map.get(theme.$z-index, step-header);
	display: flex;
	height: functions.with-unit(vars.$step-header-height);
	padding: 10px functions.with-unit(vars.$step-controls-width) 0;
	align-items: center;
	text-align: center;

	&:hover {
		.step-controls {
			opacity: 1;
			visibility: visible;
		}
	}
}

.step-name {
	display: inline-block;
	height: 100%;
	margin: 0 12px;
	flex: 1;
	text-transform: uppercase;
	color: theme.$ra-white-dark;

	&-text {
		padding: 6px 0;
		border-radius: 16px;
		transition: 0.2s ease;
	}

	&:hover .step-name-text {
		background-color: theme.$ra-white-soft;
		color: theme.$ra-white;
	}
}

.step-controls {
	position: absolute;
	top: 0;
	right: 0;
	z-index: map.get(theme.$z-index, step-controls);
	display: flex;
	width: functions.with-unit(vars.$step-controls-width);
	height: functions.with-unit(vars.$step-header-height);
	justify-content: center;
	background: none;
	opacity: 0;
	visibility: hidden;
	transition: 0.2s ease;

	&.always-visible {
		opacity: 1;
		visibility: visible;
	}

	&.left {
		left: 0;
		right: initial;
	}

	&-up {
		width: 50%;
	}

	&-down {
		width: 50%;
	}

	&-up,
	&-down {
		border: none;
		background: none;
		outline: none;
		cursor: pointer;
		display: flex;
		align-items: center;
		justify-content: center;

		svg path {
			fill: theme.$ra-white-dark;
			transition: fill 0.2s ease;
		}

		&:hover {
			svg path {
				fill: theme.$ra-white;
			}
		}
	}
	&-remove-card,
	&-add-card {
		position: relative;
		width: 50%;

		&:disabled {
			opacity: 0.7;
			cursor: not-allowed;
		}

		&:after {
			content: '';
			position: absolute;
			background-color: theme.$ra-white-dark;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			width: 10px;
			height: 2px;
			transition: background-color 0.25s ease-in;
		}

		&:after,
		&:before {
			background-color: theme.$ra-white-dark;
		}

		&:hover {
			&:after,
			&:before {
				background-color: theme.$ra-white;
			}
		}
	}

	&-add-card {
		&:before {
			content: '';
			position: absolute;
			background-color: theme.$ra-white-dark;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			width: 10px;
			height: 2px;
		}

		&:before {
			transform: translate(-50%, -50%) rotate(90deg);
		}
	}
}

.btn-add-card {
	display: block;
	width: 100%;
	height: functions.with-unit(vars.$card-thumbnail-height * 0.5);
	text-align: center;
	border-radius: 12px;
	background: hsla(0, 0%, 43%, 0.9);
	color: theme.$ra-white;
	margin-bottom: 15px;
	opacity: 0;
	visibility: hidden;
	transition: 0.25s ease;

	.step-inner:hover &,
	.step-inner &:first-child,
	.step-inner.navigation-dropdown-active & {
		opacity: 1;
		visibility: visible;
	}
}

.btn-navigation {
	display: flex;
	justify-content: center;
	width: 100%;
	color: theme.$ra-white-mid;
	text-align: center;
	opacity: 0;
	visibility: hidden;
	transition: opacity, visibility, color;
	transition-duration: 0.35s;

	.step-inner:hover & {
		opacity: 1;
		visibility: visible;
	}

	.step-inner.navigation-dropdown-active & {
		color: theme.$ra-white;
		opacity: 1;
		visibility: visible;
	}

	&-dropdown-wrapper {
		cursor: pointer;
	}

	&:hover {
		color: theme.$ra-white;
	}
}

.step-handle {
	position: absolute;
	top: 12px;
	transform: translateX(-40px);
}

.step-inner {
	height: 100%;
	padding-top: functions.with-unit(vars.$step-header-height);
	padding-bottom: functions.with-unit(vars.$step-header-height);
	// disable default scrolling
	overflow: hidden;

	// HIDE SCROLLBAR
	&::-webkit-scrollbar {
		width: 0;
		height: 2px;
		background: rgba(0, 0, 0, 0.025);
	}

	&::-webkit-scrollbar-thumb {
		background: rgba(0, 0, 0, 0.25);
	}
	// END HIDE SCROLLBAR
}

.card-remove {
	color: theme.$ra-error-color;
}

.card-menu-open {
	z-index: map.get(theme.$z-index, card-menu);

	.step-inner {
		//overflow: visible;
	}

	.card.card-active {
		z-index: map.get(theme.$z-index, card-menu);
		overflow: visible;
	}

	.card-item {
		overflow: visible;
	}

	.card-thumbnail {
		border-radius: 12px 12px 0 0;
	}
}

.stats {
	position: absolute;
	z-index: 2;
	padding: 8px 0;
	bottom: 0;
	left: #{functions.with-unit(-2.5 * vars.$card-li-padding)};
	right: functions.with-unit(-1 * vars.$card-li-padding);
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: rgba(0, 0, 0, 0.5);
	color: theme.$ra-white;
	backdrop-filter: blur(6px);

	&-item {
		position: relative;
		padding: 3px 0 0;
		flex-grow: 1;
		flex-basis: 0;

		&-content {
			display: flex;
			flex-direction: column;
			gap: 0;
			align-items: center;
			justify-content: center;
		}

		&-icon-wrapper {
			position: relative;
			width: 13px;
			height: 13px;
			margin-bottom: 2px;

			.stats-item-icon {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
			}
		}

		&-label {
			line-height: 12px;
		}

		&-value {
			letter-spacing: -0.18px;
		}

		&:not(:last-child) {
			border-right: 1px solid theme.$ra-white-soft;
		}

		&.red {
			.stats-item-value {
				color: theme.$ra-error-ds;
			}
		}

		&.green {
			.stats-item-value {
				color: theme.$ra-brand;
			}
		}
	}
}
