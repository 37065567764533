@use '/src/routes/client/styles/helpers';
@use '/src/routes/client/styles/mixins';
@use '/src/routes/client/styles/theme';

.visual-compare {
	@include mixins.darkModeInvertion(theme.$ra-darkMode-image);

	position: relative;
	width: 20vw;
	height: 20vw;
	user-select: none;

	img {
		width: 100%;
		height: 100%;
	}
}

.visual-compare-inner {
	width: 100%;
	height: 100%;
}

.content-wrapper {
	width: 100%;
	height: 100%;
}

.fade {
	.content-b-wrapper {
		opacity: 0.5;
	}
}

.spotlight {
	.content-b-container {
		mask: {
			position: var(--mask-position);
			repeat: no-repeat;
			image: var(--mask-image);
			size: var(--mask-size);
		}
	}
}

.fade,
.spotlight {
	.content-b-wrapper {
		width: 100%;
	}

	.slider-wrapper {
		top: initial;
		bottom: 20%;
		left: 50%;
		width: 15%;
		height: 2vw;
		border-radius: 1vw;
		background-color: rgba(255, 255, 255, 0.8);
		transform: translate(-50%, 0);
	}

	.slider-point {
		top: 50%;
		left: 50%;
		transform: translate(0, -50%);
	}
}

.content-b-wrapper {
	position: absolute;
	top: 0;
	left: 0;
	width: 50%;
	height: 100%;
	overflow: hidden;
}

.content-a-container,
.content-b-container {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-size: cover;
	background-position: 50% 50%;
	background-repeat: no-repeat;
}

.divider-line {
	position: absolute;
	top: 0;
	left: 50%;
	z-index: 3;
	width: 1em;
	height: 100%;
	margin-left: -0.5em;
	font-size: 0.5vw;
	background-color: #fff;
	box-shadow: 0 0 6px -2px rgba(0, 0, 0, 0.6);
}

.divider-line-bg {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	pointer-events: none;
	background-color: rgba(255, 255, 255, 1);
}

.slider-point {
	position: absolute;
	top: 50%;
	left: 50%;
	z-index: 4;
	width: 1em;
	height: 1em;
	margin-left: -0.5em;
	border-radius: 50%;
	font-size: 3vw;
	background-color: #fff;
	box-shadow: 0 0 14px -5px rgba(0, 0, 0, 0.6);
	cursor: col-resize;
	user-select: none;
	transform: translateY(-50%);

	&.no-interactive {
		cursor: default;
	}
}

.slider-point-bg {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border-radius: inherit;
	background-color: rgba(255, 255, 255, 1);
	pointer-events: none;
}
