.debugger {
	position: fixed;
	z-index: 9999;
	display: grid;
	width: 300px;
	max-height: 100vh;
	padding: 8px 8px 16px;
	grid-template-rows: 1fr 21px;
	font-size: 10px;
	font-family: monospace;
	line-height: 1.3;
	background: black;
	color: white;
	resize: both;

	&,
	& * {
		direction: ltr;
	}

	details,
	details > div {
		margin-left: 8px;
	}

	details > div {
		display: flex;
		flex-flow: column nowrap;
		row-gap: 3px;
		white-space: nowrap;
	}

	strong {
		font-weight: normal;
		color: violet;
	}

	summary {
		color: grey;
		margin-block: 3px;
	}

	button {
		width: 100%;
		padding: 5px 0;
		box-shadow: none;
		border-radius: 0;
		background: rgba(255, 255, 255, 0.15);
		color: white;
	}

	& > div {
		display: flex;
	}
}

.content {
	margin-bottom: 8px;
	flex: 1;
	overflow: auto;
	color: whitesmoke;
}
